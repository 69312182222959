import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/img/about/about-3.png';
import shapeImg1 from '../../assets/img/about/about-shape-1.png';
import shapeImg2 from '../../assets/img/about/about-shape-2.png';

const About = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="about-3__area about-3__space about-3__plr p-relative">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='PO_fBTkoznc' onClose={() => { openModal(); }} />
            <div className="container-fluid">
                <div className="row align-items-center">
          
                    <div className="col-xl-6 col-lg-6 col-md-12 mb-70 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        <div className="about-3__item">
                            <img src={aboutImg} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 mb-70 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="about-3__item">
                            <div className="about-3__section-title pb-10">
                                <h4 className="section-subtitle char-anim">Satu Data</h4>
                                <h3 className="section-title char-anim">Kami mengelola mengumpulkan, mengolah dan menyajikan data untuk pembangunan dan masyarakat.
                                </h3>
                            </div>
                            <div className="about-3__content">
                                <div className="about-3__text pb-7">
                                    <p className="char-anim-2">Portal satu data adalah portal yang digunakan oleh produsen data dan walidata untuk mengelola dan menyiapkan data. Didalam portal satudata dilakukan beberapa proses diantaranya:</p>
                                    </div>
                                    <div className="about-3__content-list wow animate__fadeInUp" data-wow-duration="1.1s">
                                    <ul>
                                        <li><i className="fal fa-check-square"></i><span>Pengumpulan dan analisa.</span></li>
                                        <li><i className="fal fa-check-square"></i><span>Integrasi dan keterpaduan</span></li>
                                        <li><i className="fal fa-check-square"></i><span>Penyiapan dan penyajian</span></li>
                                        <li><i className="fal fa-check-square"></i><span>Pemanfaatan secara luas</span></li>
                                    </ul>
                                    <div className="about-3__text">
                                        <p>Data kami siapkan untuk bisa terintegrasi dengan Data Indonesia.</p>
                                    </div>
                                </div>
                                <div className="slider-3__button mt-20">
                                <Link to="https://data.go.id" className="main-btn-sm mr-20 mb-20 wow animate__fadeInUp tp-btn-hover alt-color" data-wow-duration="1.1s">
                                    <span>Data Indonesia</span>
                                    <b></b>
                                </Link>
                                <Link to="https://satudatav2.majenekab.go.id" className="border-btn mb-20 wow animate__fadeInUp tp-btn-hover alt-color" data-wow-duration="1.1s">
                                    <span>Satu Data</span>
                                    <b></b>
                                </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;