
import { Link } from 'react-router-dom';

import blogImg from '../../../assets/img/blog/blog-7.jpg';

const SingleBlogThree = (props) => {
    const { itemClass, Image, Title, Description, Author, publishedDate, btnText,blogCategory,Url,slug_kate,imgxl } = props;
    return (
        <div className={itemClass ? itemClass : 'blog-3__item'}>
            <div className="blog-3__main-thumb fix">
                <a className="popup-image" href={imgxl ? imgxl : blogImg}>
                    <img src={Image ? Image : blogImg} alt={Title} />
                </a>
                <div className="blog-3__thumb-overly">
                    <span><Link to={"blog-category?cat="+slug_kate}>{blogCategory}</Link></span>
                </div>
            </div>
            <div className="blog-3__content">
                <div className="blog-3__meta">
                    <span>{Author ? Author : 'Admin'} .:. {publishedDate ? publishedDate : 'Nov 4, 2022'}</span>
                </div>
                <div className="blog-3__text">
                    <h4 className="blog-3__title-sm">
                        <Link to={"/blog-details?id="+Url}>{Title ? Title : 'Judul'}</Link>
                    </h4>
                    <p>{Description ? Description : 'Deskripsi'}</p>   
                </div>
                <div className="blog-3__link">
                    <Link to={"/blog-details?id="+Url}>{btnText ? btnText : 'detail'}<i className="fal fa-arrow-right"></i></Link>
                </div>
            </div>
        </div>

    )
}

export default SingleBlogThree