import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu } = props;

    const location = useLocation();

    const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [service, setService] = useState(false)
	const [page, setPage] = useState(false)
	const [blog, setBlog] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setService(false)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setService(false)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'service') {
			setHome(false)
			setAbout(false)
			setService(!service)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'page') {
			setHome(false)
			setAbout(false)
			setService(false)
			setPage(!page)
			setBlog(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setAbout(false)
			setService(false)
			setPage(false)
			setBlog(!blog)
		}
	};

    return (
        <>
            <li className={parentMenu === 'home' ? 'active' : ''}><Link to="/">Home</Link></li>
            <li className={location.pathname === "/satudata" ? "active" : ""}><Link to="https://satudatav2.majenekab.go.id">Satu Data</Link></li>
            <li className={location.pathname === "/opendata" ? "active" : ""}><Link to="https://opendata.majenekab.go.id">Open Data</Link></li>
            <li className={location.pathname === "/satupeta" ? "active" : ""}><Link to="https://satupeta.majenekab.go.id">Satu Peta</Link></li>
            <li className={location.pathname === "/dashboard" ? "active" : ""}><Link to="https://dashboard.majenekab.go.id">Dashboard Data</Link></li>
        </>
    );
}

export default MenuItems;