import React from 'react';
import { Link } from 'react-router-dom';

import bgImg from '../../assets/img/skill/skill-bg.png';
import skillImg1 from '../../assets/img/skill/skill-img-1.png';
import SingleProgress from '../../components/Progress';

const Skill = () => {

    return (
        <div className="skill-area skill-bg skill__plr" style={{backgroundImage: `url(${bgImg})`}}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 mb-60">
                        <div className="skill__section-box">
                            <h5 className="section-subtitle section-white-bg char-anim wow animate__fadeInUp" data-wow-duration="1.1s">Dashboard Data</h5>
                           <br></br><br></br><br></br>
                            <h4 className="section-title char-anim text-white wow animate__fadeInUp" data-wow-duration="1.1s">Nikmati gabungan visualisasi data <br/>
                           yang disajikan secara tematik.</h4>
                            <div className="skill-text wow animate__fadeInUp" data-wow-duration="1.1s">                                 
                            <p className="char-anim-2">Masyarakat dapat menikmati sajian visualisasi data yang disajikan berdasarkan tematik yang menggabungkan berbagai visualisasi data. </p>
                            </div>
                        </div>
                        
                        <div className="skill__btn wow animate__fadeInUp" data-wow-duration="1.1s">
                            <Link to="https://dashboard.majenekab.go.id" className="main-btn tp-btn-hover alt-color-black mr-20 mb-20 wow animate__fadeInUp" data-wow-duration="1.1s">
                                <span>Masuki Dashboard Data</span>
                                <b></b>
                            </Link>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="work-img-box text-end">
                            <img src={skillImg1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skill;