import React from "react";
import SingleBlogThree from './SingleBlogThree';

const ItemBerita = (props) => {
	let { title, description, imgUrl, newsUrl, kategori, date, source, author, imgXL } = props;
	return (
		<div className="col-xl-12 col-lg-12 col-md-6 mb-70 wow animate__fadeInUp" data-wow-duration="1.1s">
        <SingleBlogThree
            Image= {imgUrl}
            Title= {title}
            Url = {newsUrl}
            Description= {description}
            blogCategory= {kategori}
            imgxl ={imgXL}
            slug_kate = {source}
            blogAuthor= {author? author:'Admin'}
            publishedDate= {date}
            btnText= "detail"
        />
        </div>
     
	);
};

export default ItemBerita;


