import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import companyImg from '../../assets/img/portal/onemap.png';
import tabImg1 from '../../assets/img/company/company-tab-1.png';


const Company = () => {
    let tab1 = "Our Mission",
        tab2 = "Team Support",
        tab3 = "Projects Job"
        const tabStyle = 'nav nav-tab';

    return (
        
        <div className="company__area pb-100 mt-120">
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-xl-8 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="company__section-box">
                            <h4 className="section-subtitle char-anim">Satu Peta</h4>
                            <h3 className="section-title pb-10 char-anim">Satu peta menyajikan dataset berbasis geospatial</h3>
                            <p className="char-anim-2">Portal satu peta menyajikan data spatial yang juga memuat dataset <br></br>yang bisa digunakan secara luas dengan mudah</p>
                        </div>
                       
                            <div className="company__service-tab">
                                
                                <div className="tab-content" id="myTabContent">
                                  
                                        <div className="company__tab-content-wrapper d-flex align-items-center">
                                            <div className="company__tab-icon">
                                                <span><img src={tabImg1} alt="" /></span>
                                            </div>
                                            <div className="company__tab-text">
                                                <span>Anda bisa menggunakan berbagai layer data <br></br>spatial yang tersedia dengan mudah</span>
                                            </div>
                                        </div>
                                 
                                   
                                </div>
                            </div>
                        
                        <div className="company__button">
                            <Link to="https://satupeta.majenekab.go.id" className="main-btn tp-btn-hover alt-color">
                                <span>Satu Peta</span>
                                <b></b>
                            </Link>
                        </div>
                    </div>
                    <div className="col-xl-4 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        <div className="company__left-img text-center text-xl-start">
                            <img src={companyImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Company;