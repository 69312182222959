import React , { useState } from "react";
import { Link } from 'react-router-dom';
import Brand from '../../components/Brand';
import Berita from './berita/Berita';
import Satudata from './Satudata';
import Banner from './HomeFourBanner';
import Satupeta from './Satupeta';
import Opendata from './Opendata';
import Dashboard from './SkillSection';

const HomeFourMain = () => {
	const pageSize = 6;
	const apiKey = process.env.REACT_APP_NEWS_API;
	const [progress, setProgress] = useState(0);

	return (
		<main>
			<Banner />

			<Brand 
				itemClass="brand-2__area brand-2__space"
			/>
			<Berita  setProgress={setProgress} apiKey='77291a48d26948f5b5c3f5fba53229a6' key="general" pageSize={pageSize} country="id" category="general" />
			<Satudata />
			<Opendata />
			<Satupeta />
			<Dashboard />
			{/* <Testimonial /> */}

			{/* <Newsletter /> */}

		</main>
	);
}
export default HomeFourMain;