import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Newsitems from './ItemBerita';
import Axios from 'axios';




const Berita = (props) => {
  const [articles, setArticles] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const capitalFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
  const updateNews = async () => {
	props.setProgress(30);
		//const url = `/api/berita/web`;
		const url = `https://apidata.majenekab.go.id/berita/web`;
	setLoading(true);
	let data = await fetch(url,{
		method: "get",
		mode:'cors'
	});
	let parseData = await data.json();
	setArticles(parseData.data);
	setTotalResults(parseData.totalResults);
	setLoading(false);
	props.setProgress(100);
	
	};
  useEffect(() => {
		updateNews();
		
	}, []);

	const fetchMoreData = async () => {
		//const url = `/api/berita/web`;
		const url = `https://apidata.majenekab.go.id/berita/web`;
		setPage(page + 1);
		let data = await fetch(url,{
			method: "get",
			  mode:'cors'
		  });
		let parseData = await data.json();
		setArticles(articles.concat(parseData.articles));
		setTotalResults(parseData.totalResults);
	};

  return (
	
    <div className="blog-3__area blog-3__mlr pt-120 pb-70">
      <div className="container-fluid">
        <div className="row align-items-end mb-60">
          <div className="col-xl-6 col-lg-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="feature__section-box">
              <h4 className="section-blue-subtitle char-anim">Update Informasi</h4>
              <h3 className="section-title char-anim">Kami akan mengupdate  <br/>
              informasi ekosistem data disini</h3>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="blog-3__top-text">
              <h4 className="mb-0 char-anim-2">Informasi terbaru ekositem data Provinsi Sulawesi Barat. </h4>
            </div>
          </div>
        </div>
        <div className="row">
        
        {articles.map((element, index) => {
							return (
								<div className="col-md-4" key={index}>
									<Newsitems title={element.berita_judul ? element.berita_judul.slice(0, 60) : ""} description={element.berita_html ? element.berita_html.slice(0, 120) : ""} imgXL={element.berita_img_xl} imgUrl={element.berita_thumbnail ? element.berita_thumbnail : "https://images.hindustantimes.com/img/2023/01/29/1600x900/breaking_news_latest_1674951344905_1674951345196_1674951345196.jpeg"} newsUrl={element.berita_slug} kategori={element.berita_kategori} date={element.berita_tanggal} source={element.berita_kategori_slug} />
								</div>
							);
						})}
						
        
        </div>
      </div>
    </div>
  );
}

export default Berita;
Berita.defaultProps = {
	country: "in",
	pageSize: 6,
	category: "general",
};

Berita.propTypes = {
	country: PropTypes.string,
	pageSize: PropTypes.number,
	category: PropTypes.string,
};
