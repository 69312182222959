import React, { useEffect, useState } from "react";
import Detail from '../../home-4/berita/blog-details'
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';



const BlogDetailsMain = (props) => {
	const [progress, setProgress] = useState(0);
	return (
		<main>
<Header 
        parentMenu="blog"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
			
			<Detail  setProgress={setProgress}/>
			
			
			<Footer />

		</main>
	);
}

export default BlogDetailsMain;
