import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import WOW from 'wowjs';
//Custom Components


import HomeFour from '../pages/home-4';
import Blog from '../pages/blog';
import Preloader from "../components/Preloader";
import ScrollToTop from '../components/ScrollTop';
import LoadTop from '../components/ScrollTop/LoadTop'
import BlogDetails from "../pages/home-4/berita/BlogDetailsMain";

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [progress, setProgress] = useState(0);
   
  
    function handleRouteChange(location) {

        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
    }

    const location = useLocation();

    useEffect(() => {
        handleRouteChange(location);
    }, [location]);

    useEffect(() => {
        // Simulate data loading delay
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    return (
        <div className='App'>
            {isLoading ?
                <Preloader /> : ''
            }
            <>
                <ScrollToTop />
                <LoadTop />
                <Routes>
                    <Route path="/" exact element={<HomeFour />} />
                    <Route path="/blog" exact element={<Blog />} />
                    <Route path="/blog-details/*" exact element={<BlogDetails />} />
                </Routes>
            </>
        </div>
    );
}

export default App;
