import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb';
import CTA from '../../../components/CTA';

import bgImg from '../../../assets/img/cta/cta-bg-2.png';
import blogImg1 from '../../../assets/img/blog/blog-details-2.jpg';
import blogImg2 from '../../../assets/img/blog/blog-details-3.jpg';
import blogImg3 from '../../../assets/img/blog/blog-details-4.jpg';



const BlogMain = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

	return (
		<main>

			<Breadcrumb
				pageTitle= "Informasi penyelenggaran ekosistem data Pemerintah Provinsi Sulawesi Barat"
			/>
			
			<div className="postbox__area pb-100 pt-100">
				<div className="container">
					<div className="row">
						<div className="col-xxl-12 col-xl-12 col-lg-12">
							<div id="blog" className="postbox__wrapper pr-20">
								<article className="postbox__item format-image mb-50 transition-3">
									<div className="postbox__thumb w-img wow animate__fadeInUp" data-wow-duration="1.1s">
										<Link to="/blog-details">
											<img src={blogImg1} alt="" />
										</Link>
									</div>
									<div className="postbox__content wow animate__fadeInUp" data-wow-duration="1.1s">
										<div className="postbox__meta">
											<span><Link to="#">Mris Jonsong</Link></span>
											<span><Link to="#">Nov 4, 2022</Link></span>
										</div>
										<h3 className="postbox__title">
										<Link to="/blog-details">Service Construct deals physical damage with his basic attack in the match.</Link>
										</h3>
										<div className="postbox__text">
											<p>There are many variations of passages agency we have covered many special events such as 
											fireworks, fairs, parades, races, walks, a Lorem Ipsum Fasts injecte.</p>
										</div>
									</div>
								</article>
								<article className="postbox__item format-video mb-50 transition-3">
									<div className="postbox__thumb postbox__video w-img p-relative wow animate__fadeInUp" data-wow-duration="1.1s">
										<Link to="/blog-details">
											<img src={blogImg2} alt="" />
										</Link>
										<Link to="#" className="play-btn pulse-btn popup-video" onClick={() => { openModal(); }}><i className="fas fa-play"></i></Link>
									</div>
									<div className="postbox__content wow animate__fadeInUp" data-wow-duration="1.1s">
										<div className="postbox__meta">
											<span><Link to="#">Mris Jonsong</Link></span>
											<span><Link to="#">Nov 4, 2022</Link></span>
										</div>
										<h3 className="postbox__title">
											<Link to="/blog-details">Pure damage for a duration. This ability is the only interruptable by stuns</Link>
										</h3>
										<div className="postbox__text">
											<p>There are many variations of passages agency we have covered many special events such as 
											fireworks, fairs, parades, races, walks, a Lorem Ipsum Fasts injecte.</p>
										</div>
									</div>
								</article>
								<article className="postbox__item format-image mb-50 transition-3 fix">
									<div className="postbox__thumb w-img wow animate__fadeInUp" data-wow-duration="1.1s">
										<Link to="/blog-details">
											<img src={blogImg3} alt="" />
										</Link>
									</div>
									<div className="postbox__content wow animate__fadeInUp" data-wow-duration="1.1s">
										<div className="postbox__meta">
											<span><Link to="#">Resources</Link></span>
											<span><Link to="#">April 12, 2023</Link></span>
										</div>  
										<h3 className="postbox__title">
											<Link to="/blog-details">Then they need to establish a company branch office/partnership with anyone.</Link>
										</h3>
										<div className="postbox__text">
											<p>There are many variations of passages agency we have covered many special events such as 
											fireworks, fairs, parades, races, walks, a Lorem Ipsum Fasts injecte.</p>
										</div>
									</div>
								</article>
								<div className="basic-pagination wow animate__fadeInUp" data-wow-duration="1.1s">
									<nav>
										<ul>
											<li>
												<Link to="/blog">
													<i className="far fa-angle-left"></i>
												</Link>
											</li>
											<li>
												<Link className="current" href="/blog">1</Link>
											</li>
											<li>
												<Link to="/blog">2</Link>
											</li>
											<li>
												<Link to="/blog">...</Link>
											</li>
											<li>
												<Link to="/blog">3</Link>
											</li>
											<li>
												<Link to="/blog">
													<i className="far fa-angle-right"></i>
												</Link>
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>

			<CTA
				ctaBG={bgImg}
			/>

		</main>
	);
}

export default BlogMain;